// import { useState } from "react";
import "./components.css";

function Heading({currentHeading}) {
    
    return (
        <div className='headingdiv'>
            {/* <button onClick={testData}>Test Data</button> */}
            <ob-hdg-medium
                courseOverGround={55}
                heading={currentHeading}
                northUp="true"
                setPoint={40}
                showCourseOverGround=""
                showSetPoint=""
                style={{
                    height: 150,
                    width: 150
                }}
            />
        </div>
    )
};

export default Heading;