import { useState } from "react";

import Map from "./Map.jsx";
import { dateTimeConvert, timeDifference } from "../functions/datetimeconvert.js";
import latlongconvert from "../functions/latlongconvert.js";
import distance from "../functions/distance.js";

import "./components.css";

function EditTrip({ tripData }) {
    
    const [ formInputs, setFormInputs ] = useState(tripData);
    
    const formChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs(values => ({...values, [name]: value}))
    };

    const [ radioSelection, setRadioSelection ] = useState((tripData.tripActive ? "active" : "passive"));

    const radioChange = (event) => {
        setRadioSelection(event.target.value);
        if (event.target.value === 'passive') {
            formInputs.tripActive = false;
        }
        else formInputs.tripActive = true;
    };

    // get route-plotting
    const gpsRoute = tripData.gpsData.map((latLong) => {
        return latlongconvert(latLong[3], latLong[5], latLong[4], latLong[6])
    })
    // get first position of route, to present as center of map, and boat-icon
    const gpsRoutePosition = gpsRoute[0];

    // get total distance of route
    let gpsDistance = 0;
    for (let i = 0; i < gpsRoute.length - 1; i++) {
        let y = i + 1;
        gpsDistance += distance(gpsRoute[i][0], gpsRoute[i][1], gpsRoute[y][0], gpsRoute[y][1]);
    };

    // Calculate total hours and minutes of trip
    // const timeSpent = timeDifference(new Date(formInputs.startDateTime), new Date(formInputs.endDateTime));

    // Find max speed:
    const speedArray = tripData.gpsData.map((speeds) => {
        return speeds[7]
    });
    const highestSpeed = Math.max(...speedArray);

    // Find gps start, end time and hours/minutes spent
    const gpsStartTime = dateTimeConvert(tripData.gpsData[0][9], tripData.gpsData[0][1]);
    const gpsEndTime = dateTimeConvert(tripData.gpsData.slice(-1)[0][9], tripData.gpsData.slice(-1)[0][1]);
    const gpsTimeSpent = timeDifference(gpsStartTime, gpsEndTime);

    // find average speed
    const gpsTimeSpentInHours = gpsTimeSpent.hours + (gpsTimeSpent.minutes / 60)
    const gpsAvgSpeed = gpsDistance / (gpsTimeSpentInHours);


// #########################
// FUNCTION deleteTrip
//##########################
    async function deleteTrip(event) {
        event.preventDefault();

        if (window.confirm('Vil du virkelig slette turen?')) {
            console.log('Slett')
            let apiURL = '';
            if (process.env.NODE_ENV === 'production') {
                apiURL = '/api/DeleteTrip';
            }
            else {
                apiURL = 'http://localhost:7071/api/DeleteTrip';
            };
            await fetch(apiURL, {
                method: "POST",
                body: JSON.stringify(tripData._id)
            });

        };

    }

// ##########################
// FUNCTION submitForm
// puts all form-data in an json format and writes to db via api
//##########################
    async function submitForm(event) {
        event.preventDefault();

        let apiURL = '';
        if (process.env.NODE_ENV === 'production') {
            apiURL = '/api/TripDataWrite';
        }
        else {
            apiURL = 'http://localhost:7071/api/TripDataWrite';
        };
        formInputs.gpsHighestSpeed = highestSpeed;
        formInputs.gpsDistance = gpsDistance;
        formInputs.gpsTimeSpent = gpsTimeSpent;
        formInputs.gpsAvgSpeed = gpsAvgSpeed;

        await fetch(apiURL, {
            method: "POST",
            body: JSON.stringify(formInputs)
        });

    };

    return (
        <div className="edittripdiv">
            <form className="tripform edittrip-grid" id="tripform" onSubmit={submitForm}>
                <h1 className="gridheader">Redigere tur</h1>
                <label htmlFor="idstartdatetime">Start-tidspunkt:</label>
                <label htmlFor="idstartposname">Fra:</label>
                <input 
                    type="datetime-local" 
                    name="startDateTime"
                    value={formInputs.startDateTime || ""}
                    id="idstartdatetime" 
                    autoFocus={true} 
                    required
                    onChange={formChange}
                />
                <input 
                    type="text" 
                    name="startPosName"
                    value={formInputs.startPosName || ""}
                    id="idstartposname" 
                    placeholder="Stedsnavn" 
                    onChange={formChange}
                />
                <label htmlFor="idenddatetime">Slutt-tidspunkt:</label>
                <label htmlFor="idendposname">Til:</label>
                <input 
                    type="datetime-local" 
                    name="endDateTime"
                    value={formInputs.endDateTime || ""}
                    id="idenddatetime" 
                    autoFocus={true}
                    onChange={formChange}
                />
                <input 
                    type="text" 
                    name="endPosName"
                    value={formInputs.endPosName || ""}
                    id="idendposname" 
                    placeholder="Stedsnavn" 
                    onChange={formChange}
                />
                <label htmlFor="idpassengers">Passasjerer:</label>
                <label htmlFor="idnotes">Notater:</label>
                <textarea 
                    name="passengers" 
                    value={formInputs.passengers || ""}
                    id="idpassengers" 
                    rows={5}
                    cols={40}
                    placeholder="Passasjer 1, Passasjer 2, ..." 
                    onChange={formChange}
                />
                <textarea
                    id="idnotes"
                    name="notes"
                    rows={5}
                    cols={40}
                    placeholder="Beskrivelse av turen"
                    value={formInputs.notes || ""}
                    onChange={formChange}
                />
                <label htmlFor="idtripaccu">Tripteller:</label>
                <label htmlFor="idovernightstays">Antall overnattinger:</label>
                <input 
                    type="number" 
                    name="tripAccu"
                    value={formInputs.tripAccu || ""}
                    id="idtripaccu" 
                    placeholder="000" 
                    onChange={formChange}
                />
                <input 
                    type="number" 
                    name="overnightStays"
                    value={formInputs.overnightStays || ""}
                    id="idovernightstays" 
                    placeholder="0" 
                    onChange={formChange}
                />
                <label htmlFor="idenginehours">Motortimeteller:</label>
                <input 
                    type="number" 
                    name="engineHours"
                    value={formInputs.engineHours || ""}
                    id="idenginehours" 
                    placeholder="000" 
                    onChange={formChange}
                />
                <label id="idtripstatuslabel">Turstatus:</label>
                <label htmlFor="idtripactive">
                    <input
                        type="radio"
                        name="tripActive"
                        value="active"
                        checked={radioSelection === "active"}
                        id="idtripactive"
                        onChange={radioChange}
                    />
                    Aktiv
                </label>
                <label htmlFor="idtrippassive">
                    <input
                        type="radio"
                        name="tripPassive"
                        value="passive"
                        checked={radioSelection === "passive"}
                        id="idtrippassive"
                        onChange={radioChange}
                    />
                    Avsluttet
                </label>
                <input className="formbutton" type="submit" value="Lagre" />
                <button id="iddeletebutton" onClick={deleteTrip}>Slett tur</button>
            </form>
            <ul>
                <li>Høyeste fart: {highestSpeed} knop</li>
                <li>Gps start: {gpsStartTime.getDate()}.{gpsStartTime.getMonth()}.{gpsStartTime.getFullYear()} kl {('0' + gpsStartTime.getHours()).slice(-2)}:{('0' + gpsStartTime.getMinutes()).slice(-2)}.</li>
                <li>Gps slutt: {gpsEndTime.getDate()}.{gpsEndTime.getMonth()}.{gpsEndTime.getFullYear()} kl {('0' + gpsEndTime.getHours()).slice(-2)}:{('0' + gpsEndTime.getMinutes()).slice(-2)}.</li>
                <li>Gps tid brukt: {gpsTimeSpent.hours} timer og {gpsTimeSpent.minutes} minutter</li>
                <li>Gps distanse: {gpsDistance.toFixed(2)} nm</li>
                <li>Gps snittfart: {gpsAvgSpeed.toFixed(2)} knop </li>
            </ul>
            <Map gpsPosition={gpsRoutePosition} zoomLevel='13' gpsRoute={gpsRoute}/>
        </div>
    )
};

export default EditTrip;