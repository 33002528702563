import { MapContainer, Marker, Popup, TileLayer, useMap, Polyline } from "react-leaflet";
import { Icon } from "leaflet";

import "./components.css";

// Centers the map to boat
function SetViewOnClick({latlong}){
    const map = useMap();
    map.setView(latlong, map.getZoom());
    return null
};

function Map({ gpsPosition, zoomLevel, gpsRoute = []}) {

    const codingSpot = new Icon({
        iconUrl: "./images/boat.svg",
        iconSize: [60, 250],
        iconAnchor: [30, 125],
        popupAnchor: [0, -10],
      });
    
    const blueOptions = { fillcolor: 'blue' }
  
    return (
        <MapContainer 
            center={gpsPosition} 
            zoom={zoomLevel}
            scrollWheelZoom={true}
        >
            <SetViewOnClick latlong={gpsPosition}/>
            {/* <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            /> */}
          {/* <WMSTileLayer
                attribution='&copy; <a href="https://kartverket.no/">Statens kartverk</a> contributors'
                url='https://wms.geonorge.no/skwms1/wms.sjokartraster2'
                layers= 'hoved'
                crossOrigin= {true}
            /> */}
          {/* <TileLayer
                attribution='&copy; <a href="https://kartverket.no/">Statens kartverk</a> contributors'
                url='https://opencache.statkart.no/gatekeeper/gk/gk.open_wmts/sjokartraster@png/{z}/{x}/{y}.png'
                crossOrigin= {true}
            /> */}
            <TileLayer
                attribution='&copy; <a href="https://kartverket.no/">Statens kartverk</a> contributors'
                url='https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=sjokartraster&zoom={z}&x={x}&y={y}'
                crossOrigin= {true}
            />
            <Marker position={gpsPosition} icon={codingSpot}>
                <Popup>
                    Molla, Scand Nordic 27
                    LJ2714
                </Popup>
            </Marker>
            <Polyline pathOptions={blueOptions} positions={gpsRoute} />
        </MapContainer>
    )
};

export default Map;