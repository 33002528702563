function dateTimeConvert(dateString, timeString) {
   
    //get Correct year from two-digit to four-digit
    const currentDateTime = new Date();
    const currentYear = currentDateTime.getFullYear();
    let century = currentYear;
    if (parseInt(dateString.substr(4,2)) === 99 && (century % 100) !== 99) { // if given year(2-digit) is 99 and current year is later
        century = currentYear - (century % 100) - 1; // sets correct century if century change
    } else if (parseInt(dateString.substr(4,2)) < (century % 100)) {
        century = currentYear - 1;
    };

    const dateCorrected = 
        century.toString() + 
        '-' +
        dateString.substr(2,2) +
        '-' +
        dateString.substr(0,2);

    const timeCorrected =
        timeString.substr(0,2) +
        ':' +
        timeString.substr(2,2) +
        ':' +
        timeString.substr(4,2);

   const dateAndTime = new Date(dateCorrected + 'T' + timeCorrected + 'Z');

    return dateAndTime
};

function timeDifference(startTime, endTime) {
    const timeDifference = endTime - startTime;
    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.round((timeDifference - (hours * 3600000)) / 60000);
    return {
        hours: hours,
        minutes: minutes
    };
};

export { dateTimeConvert, timeDifference };