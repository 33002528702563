import { useState } from "react";

import "./components.css";

function EditFueling({ fuelData }) {
    
    const [ formInputs, setFormInputs ] = useState(fuelData);
    
    const formChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs(values => ({...values, [name]: value}))
    };

   
// #########################
// FUNCTION deleteFueling
//##########################
    async function deleteFueling(event) {
        event.preventDefault();

        if (window.confirm('Vil du virkelig slette fyllingen?')) {
            console.log('Slett')
            let apiURL = '';
            if (process.env.NODE_ENV === 'production') {
                apiURL = '/api/DeleteFuel';
            }
            else {
                apiURL = 'http://localhost:7071/api/DeleteFuel';
            };
            await fetch(apiURL, {
                method: "POST",
                body: JSON.stringify(fuelData._id)
            });

        };

    }

// ##########################
// FUNCTION submitForm
// puts all form-data in an json format and writes to db via api
//##########################
    async function submitForm(event) {
        event.preventDefault();

        let apiURL = '';
        if (process.env.NODE_ENV === 'production') {
            apiURL = '/api/FuelDataWrite';
        }
        else {
            apiURL = 'http://localhost:7071/api/FuelDataWrite';
        };

        await fetch(apiURL, {
            method: "POST",
            body: JSON.stringify(formInputs)
        });

    };

    return (
        <div className="editfueldiv">
            <form className="fuelform editfueling-grid" id="fuelform" onSubmit={submitForm}>
                <h1 className="editfueling-header-grid">Redigere fylling</h1>
                <label htmlFor="idfueldatetime">Tidspunkt</label>
                <label htmlFor="idfuelposname">Sted</label>
                <input 
                    type="datetime-local" 
                    name="fuelDateTime"
                    value={formInputs.fuelDateTime || ""}
                    id="idfueldatetime" 
                    autoFocus={true} 
                    required
                    onChange={formChange}
                />
                <input 
                    type="text" 
                    name="fuelPosName"
                    value={formInputs.fuelPosName || ""}
                    id="idfuelposname" 
                    placeholder="Sted" 
                    onChange={formChange}
                />
                <label htmlFor="idfuel">Fylte liter:</label>
                <input 
                    type="number" 
                    name="fuel"
                    value={formInputs.fuel || ""}
                    id="idfuel" 
                    placeholder="000" 
                    onChange={formChange}
                />
                <label htmlFor="idfuelenghours">Motortimer ved fylling:</label>
                <input 
                    type="number" 
                    name="fuelEngHours"
                    value={formInputs.fuelEngHours || ""}
                    id="idfuelenghours" 
                    placeholder="0000" 
                    onChange={formChange}
                />
                <label htmlFor="idfueltrip">Tripteller ved fylling:</label>
                <input 
                    type="number" 
                    name="fuelTrip"
                    value={formInputs.fuelTrip || ""}
                    id="idfueltrip" 
                    placeholder="000" 
                    onChange={formChange}
            />
                <input className="formbutton" type="submit" value="Lagre" />
                <button id="iddeletebutton" onClick={deleteFueling}>Slett fylling</button>
            </form>
            <ul>
                {/* <li>Høyeste fart: {highestSpeed} knop</li>
                <li>Gps start: {gpsStartTime.getDate()}.{gpsStartTime.getMonth()}.{gpsStartTime.getFullYear()} kl {('0' + gpsStartTime.getHours()).slice(-2)}:{('0' + gpsStartTime.getMinutes()).slice(-2)}.</li>
                <li>Gps slutt: {gpsEndTime.getDate()}.{gpsEndTime.getMonth()}.{gpsEndTime.getFullYear()} kl {('0' + gpsEndTime.getHours()).slice(-2)}:{('0' + gpsEndTime.getMinutes()).slice(-2)}.</li>
                <li>Gps tid brukt: {gpsTimeSpent.hours} timer og {gpsTimeSpent.minutes} minutter</li>
                <li>Gps distanse: {gpsDistance.toFixed(2)} nm</li>
                <li>Gps snittfart: {gpsAvgSpeed.toFixed(2)} knop </li> */}
            </ul>
        </div>
    )
};

export default EditFueling;