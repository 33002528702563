import { useState } from "react";

import "./components.css";

function NewTrip() {
    
    const [formInputs, setFormInputs] = useState({});

    const formChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs(values => ({...values, [name]: value}))
    };

// ##########################
// FUNCTION submitForm
// puts all form-data in an json format and writes to db via api
//##########################
    async function submitForm(event) {
        event.preventDefault();

        let apiURL = '';
        if (process.env.NODE_ENV === 'production') {
            apiURL = '/api/TripDataWrite';
        }
        else {
            apiURL = 'http://localhost:7071/api/TripDataWrite';
        };

        formInputs.tripId = new Date().toISOString() + Math.random().toString().substring(2, 10);
        formInputs.tripActive = true;
        formInputs.type = 'trip';
        await fetch(apiURL, {
            method: "POST",
            body: JSON.stringify(formInputs)
        });

    }

    return (
        <div>
            <form className="tripform newtrip-grid" id="tripform" onSubmit={submitForm}>
                <h1 className="newtrip-header-grid">Registrer ny tur</h1>
                <label htmlFor="idstartdatetime">Start-tidspunkt</label>
                <label htmlFor="idstartposname">Fra</label>
                <input 
                    type="datetime-local" 
                    name="startDateTime"
                    value={formInputs.startDateTime || ""}
                    id="idstartdatetime" 
                    autoFocus={true} 
                    required
                    onChange={formChange}
                />
                <input 
                    type="text" 
                    name="startPosName"
                    value={formInputs.startPosName || ""}
                    id="idstartposname" 
                    placeholder="Stedsnavn" 
                    onChange={formChange}
                />
                <label htmlFor="idpassengers">Passasjerer</label>
                <label htmlFor="idnotes">Notater</label>
                <textarea 
                    name="passengers" 
                    value={formInputs.passengers || ""}
                    id="idpassengers" 
                    rows={5}
                    cols={40}
                    placeholder="Passasjer 1, Passasjer 2, ..." 
                    onChange={formChange}
                />
                <textarea
                    name="notes"
                    value={formInputs.notes || ""}
                    id="idnotes"
                    rows={5}
                    cols={40}
                    placeholder="Beskrivelse av turen"
                    onChange={formChange}
                />
                <input className="formbutton" type="submit" value="Registrer ny tur" />
            </form>
        </div>
    )
};

export default NewTrip;