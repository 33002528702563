import { useState } from "react";

import "./components.css";

function NewFueling() {
    
    const [formInputs, setFormInputs] = useState({});

    const formChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormInputs(values => ({...values, [name]: value}))
    };

// ##########################
// FUNCTION submitForm
// puts all form-data in an json format and writes to db via api
//##########################
    async function submitForm(event) {
        event.preventDefault();

        let apiURL = '';
        if (process.env.NODE_ENV === 'production') {
            apiURL = '/api/FuelDataWrite';
        }
        else {
            apiURL = 'http://localhost:7071/api/FuelDataWrite';
        };

        formInputs.fuelId = new Date().toISOString() + Math.random().toString().substring(2, 10);
        formInputs.type = 'fuel';
        await fetch(apiURL, {
            method: "POST",
            body: JSON.stringify(formInputs)
        });

    }

    return (
        <div>
            <form className="fuelform newfueling-grid" id="fuelform" onSubmit={submitForm}>
                <h1 className="newfueling-header-grid">Registrer ny fylling</h1>
                <label htmlFor="idfueldatetime">Tidspunkt</label>
                <label htmlFor="idfuelposname">Sted</label>
                <input 
                    type="datetime-local" 
                    name="fuelDateTime"
                    value={formInputs.fuelDateTime || ""}
                    id="idfueldatetime" 
                    autoFocus={true} 
                    required
                    onChange={formChange}
                />
                <input 
                    type="text" 
                    name="fuelPosName"
                    value={formInputs.fuelPosName || ""}
                    id="idfuelposname" 
                    placeholder="Sted" 
                    onChange={formChange}
                />
                <label htmlFor="idfuel">Fylte liter:</label>
                <input 
                    type="number" 
                    name="fuel"
                    value={formInputs.fuel || ""}
                    id="idfuel" 
                    placeholder="000" 
                    onChange={formChange}
                />
                <label htmlFor="idfuelenghours">Motortimer ved fylling:</label>
                <input 
                    type="number" 
                    name="fuelEngHours"
                    value={formInputs.fuelEngHours || ""}
                    id="idfuelenghours" 
                    placeholder="timer" 
                    onChange={formChange}
                />
                <label htmlFor="idfueltrip">Tripteller ved fylling:</label>
                <input 
                    type="number" 
                    name="fuelTrip"
                    value={formInputs.fuelTrip || ""}
                    id="idfueltrip" 
                    placeholder="trip" 
                    onChange={formChange}
            />
                <input className="formbutton" type="submit" value="Registrer ny fylling" />
            </form>
        </div>
    )
};

export default NewFueling;