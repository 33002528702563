import { useState, useEffect } from "react";
// import ReactModal from "react-modal";
// import Modal from "react-modal";

import Heading from "./Heading";
import Speedometer from "./Speedometer";
import Map from "./Map";

import latlongconvert from "../functions/latlongconvert";

import "./components.css";

function Hud() {

    // Modal.setAppElement('#root');

    const [ speed, setSpeed ] = useState(0);
    const [ heading, setHeading ] = useState(0);
    const [ updateTime, setUpdateTime ] = useState('-laster-');
    const [ position, setPosition ] = useState([62.7248759, 7.2950352]);

    //###########################
    // FUNCTION fetchData)
    // Connects to API and fetches document with latest gps-data
    //###########################

    async function fetchData() {

        // Sets api-url based on if development or in azure(production)
        let apiURL = '';
        if (process.env.NODE_ENV === 'production') {
            apiURL = '/api/GetGPSData';
        }
        else {
            apiURL = 'http://localhost:7071/api/GetGPSData';

        };

        // fetch data via self-made api
        const data = await( await fetch(apiURL, {
            method: "GET"
        })).json();

        // Change data to more readable date and time
        const gpsDate = data.lastGpsData[9].substr(0,2) + '.' + data.lastGpsData[9].substr(2,2) + '.' + data.lastGpsData[9].substr(4,2);
        const gpsTimeH = parseInt(data.lastGpsData[1].substr(0,2)) + 2;
        const gpsTime = gpsTimeH.toString() + ':' + data.lastGpsData[1].substr(2,2) + ':' + data.lastGpsData[1].substr(4,2);
        
        setPosition(latlongconvert(data.lastGpsData[3], data.lastGpsData[5], data.lastGpsData[4], data.lastGpsData[6]));

        // setting hook data.
        setSpeed(data.lastGpsData[7]);
        setHeading(data.lastGpsData[8]);
        setUpdateTime(gpsDate + '-' + gpsTime);
    };

    // useEffect makes this run without causing re-render
    // but setInterval makes code run by interval
    useEffect(() => {
        fetchData();
        // const interval = setInterval(() => fetchData(), 120000);
    
        // return () => clearInterval(interval);
      }, []);

    return (
        <div className="huddiv">
            <div className="hud-grid">
                <h1 className="hud-grid-header-left">Gjeldende kurs: {heading}</h1>
                <h1 className="hud-grid-header-right">Gjeldende fart: {speed}</h1>
                <Heading className="hud-grid-heading" currentHeading={heading}/>
                <Speedometer className="hud-grid-speedometer" currentSpeed={speed}/>
                <button className="hud-grid-refresh" onClick={fetchData}>Oppdater ({updateTime})</button>
            </div>
            <div className="hud-map">
                <Map gpsPosition={position} zoomLevel='13' />
            </div>
        </div>
    )
};

export default Hud;