import "./components.css";

function Speedometer({currentSpeed}) {
    
    return (
        <div className='speedometerdiv'>
            <ob-speedometer-medium
                maxValue={20}
                setpoint={17}
                style={{
                    height: 150,
                    width: 150
                }}
                value={currentSpeed}
            />
        </div>
    )
};

export default Speedometer;