import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Modal from "react-modal";
import { MdClose } from 'react-icons/md';

import NewTrip from "./Newtrip";
import EditTrip from "./EditTrip";

import "./components.css";

function Trips() {
    Modal.setAppElement('#root');
    const [ arrayOfTrips, setArrayOfTrips ] = useState([]);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modal2Open, setModal2Open ] = useState(false);
    const [ tripDataToEdit, setTripDataToEdit ] = useState([]);

    function closeModal() {
        setModalOpen(false);
    };

    function closeModal2() {
        setModal2Open(false);
    };

    function registerNewTrip() {
        setModalOpen(true);
    };

    function editTrip(tripItem) {
        setTripDataToEdit(tripItem);
        setModal2Open(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Sets api-url based on if development or in azure(production)
            let apiURL = '';
            if (process.env.NODE_ENV === 'production') {
                apiURL = '/api/TripDataRead';
            }
            else {
                apiURL = 'http://localhost:7071/api/TripDataRead';

            };

            // fetch data via self-made api
            const data = await( await fetch(apiURL + '?tripId=*', {
                method: "GET"
                })).json();
            setArrayOfTrips(data);
        };
        fetchData();
    }, []);
    
    return (
        <div className="tripsdiv">
            <button onClick={registerNewTrip}>Registrer ny tur</button>
            <table>
                <thead>
                    <tr>
                        <th>Dato</th>
                        <th>Fra</th>
                        <th>Til</th>
                        <th>Distanse</th>
                        <th>Snitthastighet</th>
                    </tr>
                </thead>
                <tbody>
                    {arrayOfTrips.map((item) => 
                        <tr key={item._id} onClick={() => editTrip(item)}>
                            <td>{new Date(item.startDateTime).toLocaleDateString()}</td>
                            <td>{item.startPosName}</td>
                            <td>{item.endPosName}</td>
                            <td>{Number(item.gpsDistance).toFixed(2)}</td>
                            <td>{Number(item.gpsAvgSpeed).toFixed(2)}</td>
                        </tr>)}
                    </tbody>
            </table>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modalOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className="modalclosebtn" onClick={closeModal}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <NewTrip />
            </ReactModal>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modal2Open}
                onRequestClose={closeModal2}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className="modalclosebtn" onClick={closeModal2}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <EditTrip tripData={tripDataToEdit} />
            </ReactModal>
        </div>
    )
};

export default Trips;