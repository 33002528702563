import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Modal from "react-modal";
import { MdClose } from 'react-icons/md';

import NewFueling from "./Newfueling";
import EditFueling from "./EditFueling";

import "./components.css";


function Fuel() {

    Modal.setAppElement('#root');
    const [ arrayOfFuelings, setArrayOfFuelings ] = useState([]);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modal2Open, setModal2Open ] = useState(false);
    const [ fuelDataToEdit, setFuelDataToEdit ] = useState([]);

    function closeModal() {
        setModalOpen(false);
    };

    function closeModal2() {
        setModal2Open(false);
    };

    function registerNewFueling() {
        setModalOpen(true);
    };

    function editFueling(fuelItem) {
        setFuelDataToEdit(fuelItem);
        setModal2Open(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Sets api-url based on if development or in azure(production)
            let apiURL = '';
            if (process.env.NODE_ENV === 'production') {
                apiURL = '/api/FuelDataRead';
            }
            else {
                apiURL = 'http://localhost:7071/api/FuelDataRead';

            };

            // fetch data via self-made api
            const data = await( await fetch(apiURL + '?fuelId=*', {
                method: "GET"
                })).json();
            setArrayOfFuelings(data);
        };
        fetchData();
    }, []);


    return (
        <div className="fueldiv">
            <button onClick={registerNewFueling}>Registrer ny fylling</button>
            <table>
                <thead>
                    <tr>
                        <th>Dato</th>
                        <th>Sted</th>
                        <th>Liter</th>
                        <th>Motortimer</th>
                        <th>Trip</th>
                    </tr>
                </thead>
                <tbody>
                    {arrayOfFuelings.map((item) => 
                        <tr key={item._id} onClick={() => editFueling(item)}>
                            <td>{new Date(item.fuelDateTime).toLocaleDateString()}</td>
                            <td>{item.fuelPosName}</td>
                            <td>{item.fuel}</td>
                            <td>{item.fuelEngHours}</td>
                            <td>{item.fuelTrip}</td>

                        </tr>)}
                    </tbody>
            </table>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modalOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className="modalclosebtn" onClick={closeModal}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <NewFueling />
            </ReactModal>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modal2Open}
                onRequestClose={closeModal2}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className="modalclosebtn" onClick={closeModal2}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <EditFueling fuelData={fuelDataToEdit} />
            </ReactModal>
        </div>
    )
};

export default Fuel;