import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { FiMenu } from 'react-icons/fi';

import ReactModal from "react-modal";
import Modal from "react-modal";

import Trips from './Trips';
import Fuel from './Fuel';

import './components.css';

function Nav() {

    Modal.setAppElement('#root');
    const [ modalOpen, setModalOpen ] = useState(false);
    function closeModal() {
        setModalOpen(false);
    };
    const [ modal2Open, setModal2Open ] = useState(false);
    function closeModal2() {
        setModal2Open(false);
    };


    const [ hamburgerOpen, setHamburgerOpen ] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    };

    function signedIn() {
        const dateToken = new Date().getTime();
        // checks localstorage if already logged in
        let userName = localStorage.getItem('username');
        let token = localStorage.getItem('token');
        if (userName === 'zulumike' && token > (dateToken - 604800000)) {  // if username correct and less than 7 days old token
            return true;
        } else {
            // prompts user for username and password
            userName = prompt('Brukernavn?');
            const password = prompt('Passord?');
            if (userName === 'zulumike' && password === 'Perkele18') {
                localStorage.setItem('username', userName);
                localStorage.setItem('token', dateToken);
                return true
            }
            else return false;
        }
    };


    function openTrips() {
        
        if (signedIn()) {
            setModalOpen(true);
            toggleHamburger();
        } else alert('Du har ikke tillatelse til dette');
    };

    function openFuelings() {
        if (signedIn()) {
            setModal2Open(true);
            toggleHamburger();
        } else alert('Du har ikke tillatalse til dette')
    }

    return (
        <nav className="navigation">
            <button
                className="hamburgerbutton"
                onClick={toggleHamburger}>
                    {hamburgerOpen ? (
                        <MdClose style={{ width: '32px', height: '32px' }} />
                        ) : (
                        <FiMenu
                        style={{
                            width: '32px',
                            height: '32px',
                        }}
                        />
                    )}
            </button>
            <ul className={`menu-nav${hamburgerOpen ? ' show-menu' : ''}`}>
                <li onClick={openTrips}>Loggbok</li>
                <li onClick={openFuelings}>Drivstoff</li>
            </ul>

            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modalOpen}
                onRequestClose={closeModal}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className='modalclosebtn' onClick={closeModal}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <Trips key="123"/>
            </ReactModal>
            <ReactModal 
                className='modal'
                ovarlayClassName='modaloverlay'
                presentationStyle='fullScreen'
                isOpen={modal2Open}
                onRequestClose={closeModal2}
                shouldCloseOnOverlayClick={true}
                shouldCloseOnEsc={true}
            >
                <button className='modalclosebtn' onClick={closeModal2}>
                    <MdClose style={{ width: '32px', height: '32px' }} />
                </button>
                <Fuel key="123"/>
            </ReactModal>
        </nav>
        
    )
};

export default Nav;