//##########################
// FUNCTION 
// Takes in latitude and longitude by degree, minute, second
// to degree decimal and returns this
//##########################

function latlongconvert(lat, long, nORS, eORv) {
    let latitude, longitude = 0;
    const latDegree = parseFloat(lat.substr(0, 2));
    const latDegreeMin = parseFloat(lat.substr(2, 9)) / 60;

    // if not North the number must be in minus
    if (nORS === 'N') {
        latitude = latDegree + latDegreeMin;
    }
    else {
        latitude = (latDegree + latDegreeMin) * -1;
    }

    const longDegree = parseFloat(long.substr(0, 3));
      
    const longDegreeMin = parseFloat(long.substr(3, 9)) / 60;

    // if not East, the number must be in minus
    if (eORv === 'E') {
        longitude = longDegree + longDegreeMin;
    }
    else {
        longitude = (longDegree + longDegreeMin) * -1;
    }

    return [latitude, longitude]
};

export default latlongconvert;